import http from '../clients/Axios'

export default class AccountService {
  async paginatePenerimaan(
    pageNumber,
    pageSize = 10,
    sortField,
    sortOrder,
    filters
  ) {
    pageNumber = pageNumber === undefined ? 1 : pageNumber
    const sort = sortOrder === 1 ? '-' + sortField : sortField

    let filters_param = ''

    if (filters) {
      const keys = Object.keys(filters)

      keys.forEach((el) => {
        if (filters[el].value) {
          filters_param =
            filters_param + '&filter[' + el + ']=' + filters[el].value
        }
      })
    }

    const res = await http.get(
      `/account/kas/masuk/paginate?page[number]=${pageNumber}&page[size]=${pageSize}&sort=${sort}${filters_param}&timestamp=${new Date().getTime()}`
    )

    return res.data
  }

  async paginatePengeluaran(
    pageNumber,
    pageSize = 10,
    sortField,
    sortOrder,
    filters
  ) {
    pageNumber = pageNumber === undefined ? 1 : pageNumber
    const sort = sortOrder === 1 ? '-' + sortField : sortField

    let filters_param = ''

    if (filters) {
      const keys = Object.keys(filters)

      keys.forEach((el) => {
        if (filters[el].value) {
          filters_param =
            filters_param + '&filter[' + el + ']=' + filters[el].value
        }
      })
    }

    const res = await http.get(
      `/account/kas/keluar/paginate?page[number]=${pageNumber}&page[size]=${pageSize}&sort=${sort}${filters_param}&timestamp=${new Date().getTime()}`
    )

    return res.data
  }

  async getPenerimaan(param) {
    param = param ? param : ''
    return await http.get(`/account/kas/masuk${param}`)
  }

  async addPenerimaan(form) {
    return await http.post('/account/kas/masuk', form)
  }

  async updatePenerimaan(form) {
    const deleteDetail = form.deleted
    if (deleteDetail) {
      for (const el of deleteDetail) {
        await http.delete(`/account/kas/masuk/${form.id}/${el}`, form)
      }
    }
    return await http.put(`/account/kas/masuk/${form.id}`, form)
  }

  async deletePenerimaan(id) {
    return await http.delete(`/account/kas/masuk/${id}`)
  }

  async printPenerimaan(id) {
    return http({
      url: `/account/kas/masuk/${id}/print`,
      method: 'GET',
      responseType: 'blob',
    })
  }

  async getPengeluaran(param) {
    param = param ? param : ''
    return await http.get(`/account/kas/keluar${param}`)
  }

  async addPengeluaran(form) {
    return await http.post('/account/kas/keluar', form)
  }

  async updatePengeluaran(form) {
    const deleteDetail = form.deleted
    if (deleteDetail) {
      for (const el of deleteDetail) {
        await http.delete(`/account/kas/keluar/${form.id}/${el}`, form)
      }
    }
    return await http.put(`/account/kas/keluar/${form.id}`, form)
  }

  async deletePengeluaran(id) {
    return await http.delete(`/account/kas/keluar/${id}`)
  }

  async printPengeluaran(id) {
    return http({
      url: `/account/kas/keluar/${id}/print`,
      method: 'GET',
      responseType: 'blob',
    })
  }

  async export() {
    return http({
      url: '/account/print',
      method: 'GET',
      responseType: 'blob',
    })
  }

  // ::::::::::::::Transfer Kas::::::::::::
  async paginateTransfer(
    pageNumber,
    pageSize = 10,
    sortField,
    sortOrder,
    filters
  ) {
    pageNumber = pageNumber === undefined ? 1 : pageNumber
    const sort = sortOrder === 1 ? '-' + sortField : sortField

    let filters_param = ''

    if (filters) {
      const keys = Object.keys(filters)

      keys.forEach((el) => {
        if (filters[el].value) {
          filters_param =
            filters_param + '&filter[' + el + ']=' + filters[el].value
        }
      })
    }

    const res = await http.get(
      `/account/kas/transfer/paginate?page[number]=${pageNumber}&page[size]=${pageSize}&sort=${sort}${filters_param}&timestamp=${new Date().getTime()}`
    )

    return res.data
  }

  async getTransfer(param) {
    param = param ? param : ''
    return await http.get(`/account/kas/transfer${param}`)
  }

  async addTransfer(form) {
    return await http.post('/account/kas/transfer', form)
  }

  async updateTransfer(form, id) {
    return await http.post(`/account/kas/transfer/${id}`, form)
  }

  async deleteTransfer(id) {
    return await http.delete(`/account/kas/transfer/${id}`)
  }

  async exportTransfer(sortField, sortOrder, filters) {
    const sort = sortOrder === 1 ? '-' + sortField : sortField

    let filters_param = ''

    if (filters) {
      const keys = Object.keys(filters)

      keys.forEach((el) => {
        if (filters[el].value) {
          filters_param =
            filters_param + '&filter[' + el + ']=' + filters[el].value
        }
      })
    }

    const unixTime = Math.floor(Date.now() / 1000)
    return http({
      url: `/account/kas/transfer/export?sort=${sort}${filters_param}&t=${unixTime}`,
      method: 'GET',
      responseType: 'blob',
    })
  }

  // :::::::::::::: Giro Masuk ::::::::::::
  async paginateGiroMasuk(
    pageNumber,
    pageSize = 10,
    sortField,
    sortOrder,
    filters
  ) {
    pageNumber = pageNumber === undefined ? 1 : pageNumber
    const sort = sortOrder === 1 ? '-' + sortField : sortField

    let filters_param = ''

    if (filters) {
      const keys = Object.keys(filters)

      keys.forEach((el) => {
        if (filters[el].value) {
          filters_param =
            filters_param + '&filter[' + el + ']=' + filters[el].value
        }
      })
    }

    const res = await http.get(
      `/account/giro/masuk/paginate?page[number]=${pageNumber}&page[size]=${pageSize}&sort=${sort}${filters_param}`
    )

    return res.data
  }

  async getGiroMasuk() {
    return await http.get('/account/giro/masuk')
  }

  async updateGiroMasuk(form) {
    return await http.post(`/account/giro/masuk/${form.id}`, form)
  }

  async exportGiroMasukPdf(sortField, sortOrder, filters) {
    const sort = sortOrder === 1 ? '-' + sortField : sortField

    let filters_param = ''

    if (filters) {
      const keys = Object.keys(filters)

      keys.forEach((el) => {
        if (filters[el].value) {
          filters_param =
            filters_param + '&filter[' + el + ']=' + filters[el].value
        }
      })
    }

    return http({
      url: `/account/giro/masuk/print?sort=${sort}${filters_param}`,
      method: 'GET',
      responseType: 'blob',
    })
  }

  async exportGiroMasukExcel(sortField, sortOrder, filters) {
    const sort = sortOrder === 1 ? '-' + sortField : sortField

    let filters_param = ''

    if (filters) {
      const keys = Object.keys(filters)

      keys.forEach((el) => {
        if (filters[el].value) {
          filters_param =
            filters_param + '&filter[' + el + ']=' + filters[el].value
        }
      })
    }

    const unixTime = Math.floor(Date.now() / 1000)
    return http({
      url: `/account/giro/masuk/export?sort=${sort}${filters_param}&t=${unixTime}`,
      method: 'GET',
      responseType: 'blob',
    })
  }

  // :::::::::::::: Giro Keluar ::::::::::::
  async paginateGiroKeluar(
    pageNumber,
    pageSize = 10,
    sortField,
    sortOrder,
    filters
  ) {
    pageNumber = pageNumber === undefined ? 1 : pageNumber
    const sort = sortOrder === 1 ? '-' + sortField : sortField

    let filters_param = ''

    if (filters) {
      const keys = Object.keys(filters)

      keys.forEach((el) => {
        if (filters[el].value) {
          filters_param =
            filters_param + '&filter[' + el + ']=' + filters[el].value
        }
      })
    }

    const res = await http.get(
      `/account/giro/keluar/paginate?page[number]=${pageNumber}&page[size]=${pageSize}&sort=${sort}${filters_param}`
    )

    return res.data
  }

  async getGiroKeluar() {
    return await http.get('/account/giro/keluar')
  }

  async updateGiroKeluar(form) {
    return await http.post(`/account/giro/keluar/${form.id}`, form)
  }

  async exportGiroKeluarPdf(sortField, sortOrder, filters) {
    const sort = sortOrder === 1 ? '-' + sortField : sortField

    let filters_param = ''

    if (filters) {
      const keys = Object.keys(filters)

      keys.forEach((el) => {
        if (filters[el].value) {
          filters_param =
            filters_param + '&filter[' + el + ']=' + filters[el].value
        }
      })
    }

    return http({
      url: `/account/giro/keluar/print?sort=${sort}${filters_param}`,
      method: 'GET',
      responseType: 'blob',
    })
  }

  async exportGiroKeluarExcel(sortField, sortOrder, filters) {
    const sort = sortOrder === 1 ? '-' + sortField : sortField

    let filters_param = ''

    if (filters) {
      const keys = Object.keys(filters)

      keys.forEach((el) => {
        if (filters[el].value) {
          filters_param =
            filters_param + '&filter[' + el + ']=' + filters[el].value
        }
      })
    }

    const unixTime = Math.floor(Date.now() / 1000)
    return http({
      url: `/account/giro/keluar/export?sort=${sort}${filters_param}&t=${unixTime}`,
      method: 'GET',
      responseType: 'blob',
    })
  }

  // :::::::::::::: Jurnal Umum ::::::::::::
  async paginateJurnal(
    pageNumber,
    pageSize = 10,
    sortField,
    sortOrder,
    filters
  ) {
    pageNumber = pageNumber === undefined ? 1 : pageNumber
    const sort = sortOrder === 1 ? '-' + sortField : sortField

    let filters_param = ''

    if (filters) {
      const keys = Object.keys(filters)

      keys.forEach((el) => {
        if (filters[el].value) {
          filters_param =
            filters_param + '&filter[' + el + ']=' + filters[el].value
        }
      })
    }

    const res = await http.get(
      `/account/jurnal_umum/paginate?page[number]=${pageNumber}&page[size]=${pageSize}&sort=${sort}${filters_param}`
    )

    return res.data
  }

  async getJurnal(param) {
    param = param ? param : ''
    return await http.get(`/account/jurnal_umum${param}`)
  }

  async addJurnal(form) {
    return await http.post('/account/jurnal_umum', form)
  }

  async updateJurnal(form) {
    const deleteDetail = form.deleted
    if (deleteDetail) {
      for (const el of deleteDetail) {
        await http.delete(`/account/jurnal_umum/${form.id}/${el}`, form)
      }
    }
    return await http.put(`/account/jurnal_umum/${form.id}`, form)
  }

  async deleteJurnal(id) {
    return await http.delete(`/account/jurnal_umum/${id}`)
  }

  async printJurnal(id) {
    return http({
      url: `/account/jurnal_umum/${id}/print`,
      method: 'GET',
      responseType: 'blob',
    })
  }

  async exportJurnalPdf(sortField, sortOrder, filters) {
    const sort = sortOrder === 1 ? '-' + sortField : sortField

    let filters_param = ''

    if (filters) {
      const keys = Object.keys(filters)

      keys.forEach((el) => {
        if (filters[el].value) {
          filters_param =
            filters_param + '&filter[' + el + ']=' + filters[el].value
        }
      })
    }

    return http({
      url: `/account/jurnal_umum/print?sort=${sort}${filters_param}`,
      method: 'GET',
      responseType: 'blob',
    })
  }

  async exportJurnalExcel(sortField, sortOrder, filters) {
    const sort = sortOrder === 1 ? '-' + sortField : sortField

    let filters_param = ''

    if (filters) {
      const keys = Object.keys(filters)

      keys.forEach((el) => {
        if (filters[el].value) {
          filters_param =
            filters_param + '&filter[' + el + ']=' + filters[el].value
        }
      })
    }

    const unixTime = Math.floor(Date.now() / 1000)
    return http({
      url: `/account/jurnal_umum/export?sort=${sort}${filters_param}&t=${unixTime}`,
      method: 'GET',
      responseType: 'blob',
    })
  }

  // ::::::::::::::Account::::::::::::
  async paginateAccount(
    pageNumber,
    pageSize = 10,
    sortField,
    sortOrder,
    filters
  ) {
    pageNumber = pageNumber === undefined ? 1 : pageNumber
    const sort = sortOrder === 1 ? '-' + sortField : sortField

    let filters_param = ''

    if (filters) {
      const keys = Object.keys(filters)

      keys.forEach((el) => {
        if (filters[el].value) {
          filters_param =
            filters_param + '&filter[' + el + ']=' + filters[el].value
        }
      })
    }

    filters_param += '&filter[level]=3,4'
    const res = await http.get(
      `/account/paginate?page[number]=${pageNumber}&page[size]=${pageSize}&sort=${sort}${filters_param}`
    )

    return res.data
  }

  async get(param) {
    param = param ? param : ''
    return await http.get(`/account${param}`)
  }

  async getAccounts() {
    return await http.get('/account?sort=kode')
  }

  async getAccount(subgroup_id) {
    return await http.get(`/account?filter[parent_id]=${subgroup_id}`)
  }

  // ::::::::::::::Head Account::::::::::::
  async paginateAccountHead(
    pageNumber,
    pageSize = 10,
    sortField,
    sortOrder,
    filters
  ) {
    pageNumber = pageNumber === undefined ? 1 : pageNumber
    const sort = sortOrder === 1 ? '-' + sortField : sortField

    let filters_param = ''

    if (filters) {
      const keys = Object.keys(filters)

      keys.forEach((el) => {
        if (filters[el].value) {
          filters_param =
            filters_param + '&filter[' + el + ']=' + filters[el].value
        }
      })
    }

    const res = await http.get(
      `/account/head/paginate?page[number]=${pageNumber}&page[size]=${pageSize}&sort=${sort}${filters_param}`
    )

    return res.data
  }

  async getAccountHead() {
    return await http.get('/account/head')
  }

  async updateAccount(form) {
    return await http.post(`/account/head/${form.id}`, form)
  }

  // ::::::::::::::Kelompok Account::::::::::::
  async paginateAccountGroup(
    head_id = 0,
    pageNumber,
    pageSize = 10,
    sortField,
    sortOrder
  ) {
    pageNumber = pageNumber === undefined ? 1 : pageNumber
    const sort = sortOrder === 1 ? '-' + sortField : sortField

    let filters_param = ''
    if (head_id > 0) {
      filters_param = `filter[parent_id]=${head_id}`
    }

    const res = await http.get(
      `/account/group/paginate?page[number]=${pageNumber}&page[size]=${pageSize}&sort=${sort}&${filters_param}`
    )

    return res.data
  }

  async getAccountGroup() {
    return await http.get(`/account/group`)
  }

  async getAccountGroupFilter(id) {
    return await http.get(`/account/group?filter[parent_id]=${id}`)
  }

  async addAccountGroup(form) {
    return await http.post('/account/group', form)
  }

  async updateAccountGroup(form, id) {
    return await http.put(`/account/group/${id}`, form)
  }

  async deleteAccountGroup(id) {
    return await http.delete(`/account/group/${id}`)
  }

  // ::::::::::::::Sub Kelompok Account::::::::::::
  async paginateAccountSGroup(
    parent_id = 0,
    pageNumber,
    pageSize = 10,
    sortField,
    sortOrder,
    filters
  ) {
    pageNumber = pageNumber === undefined ? 1 : pageNumber
    const sort = sortOrder === 1 ? '-' + sortField : sortField

    let filters_param = ''

    if (filters) {
      const keys = Object.keys(filters)

      keys.forEach((el) => {
        if (filters[el].value) {
          filters_param =
            filters_param + '&filter[' + el + ']=' + filters[el].value
        }
      })
    }

    let filters_parent = ''
    if (parent_id > 0) {
      filters_parent = `&filter[parent_id]=${parent_id}`
    }

    const res = await http.get(
      `/account/sgroup/paginate?page[number]=${pageNumber}&page[size]=${pageSize}&sort=${sort}${filters_param}${filters_parent}`
    )

    return res.data
  }

  async getAccountSGroup() {
    return await http.get(`/account/sgroup`)
  }

  async getAccountSubGroup(group_id) {
    return await http.get(`/account/sgroup?filter[parent_id]=${group_id}`)
  }

  async addAccountSGroupNoSub(form) {
    return await http.post('/account/sgroup', form)
  }

  async updateAccountSGroupNoSub(form, id) {
    return await http.post(`/account/sgroup/${id}`, form)
  }

  async addAccountSGroupWithSub(form) {
    return await http.post('/account/sgroup', form)
  }

  async updateAccountSGroupWithSub(form, id) {
    return await http.post(`/account/sgroup/${id}`, form)
  }

  async deleteAccountSGroup(id) {
    return await http.delete(`/account/sgroup/${id}`)
  }
}
